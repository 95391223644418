import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import SODMapChart from '../../components/charts/map/sod/sod-map-chart';
import ContainerBox from '../../components/container-box/container-box';
import Layout from '../../components/layout/layout';
import Share from '../../components/share/share';
import SocialMetadata from '../../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../../components/social-metadata/social-metadata-enum';
import WhatToDoHome from '../../components/what-to-do-home/what-to-do-home';
import { gradeSVG } from '../../global/svg/grades';
import {
  ICriteriaProperties,
  IPageContentProperties,
} from './criteria-interface';

export default class Criteria extends BaseComponent<ICriteriaProperties> {
  protected declareTranslateCollection(): string {
    return 'criteria';
  }

  public render(): ReactNode {
    const {
      pageContext: { name, measure },
      data,
    } = this.props;
    const pageContent: IPageContentProperties = {};
    data.homePageContent.nodes.forEach((content) => {
      pageContent[content.title] = content.value;
    });
    const {
      overallSubtitle,
      competitionSubtitle,
      polarizationSubtitle,
      participationSubtitle,
      representationSubtitle,
      findYourState,
      findYourStateDesc,
      getInvolved,
      getInvolvedDesc,
      getTheFacts,
      getTheFactsDesc,
      CTA,
      CTALink,
    } = pageContent;
    let subtitle = '';

    switch (name) {
      case 'participation':
        subtitle = participationSubtitle;
        break;
      case 'competition':
        subtitle = competitionSubtitle;
        break;
      case 'representation':
        subtitle = representationSubtitle;
        break;
      case 'polarization':
        subtitle = polarizationSubtitle;
        break;
      default:
        subtitle = overallSubtitle;
    }

    return (
      <Layout
        title={this.translate(`${name}Title`)}
        subtitle={subtitle}
        description={this.translate(`${name}Description`)}
      >
        <SocialMetadata
          type={SocialMetadataType.SUMMARY}
          title={this.translate(`${name}Title`)}
          description={this.translate(`${name}Description`)}
          imageResourceFileName={`embedded-${name}-map.png`}
        />
        <ContainerBox className="c-section--map" id="map">
          <div className="row c-map-details">
            <div className="col-md-8 c-map-info">
              <div className="c-map__grade">
                <span className="c-grade__text is-title">
                  {this.translate('grade')}
                </span>
                {gradeSVG}
                <span className="c-grade__text">
                  {this.translate('gradeText')}
                </span>
              </div>
            </div>
            <div className="col-md-4 c-map-share u-hide-mobile">
              <Share
                absoluteLink={`/${name}`}
                embeddedLink={`/embedded/map/${name}`}
                imageResourceFileName={`embedded-${name}-map.png`}
              />
            </div>
          </div>
          <SODMapChart measure={measure} data={data} isTooltip={true} />
          <div className="c-map-share is-full u-hide-desktop">
            <Share
              absoluteLink={`/${name}`}
              embeddedLink={`/embedded/map/${name}`}
              imageResourceFileName={`embedded-${name}-map.png`}
            />
          </div>
        </ContainerBox>
        <ContainerBox className="c-section--todo">
          <WhatToDoHome
            items={[
              {
                title: findYourState,
                description: findYourStateDesc,
              },
              {
                title: getTheFacts,
                description: getTheFactsDesc,
              },
              {
                title: getInvolved,
                description: getInvolvedDesc,
                moreInfoLink: CTALink,
                moreInfoTitle: CTA,
              },
            ]}
          />
        </ContainerBox>
      </Layout>
    );
  }
}

export const query = graphql`
  query GetCriteriaBasedData($measure: String) {
    overallData: allSheetDataGrandTotal {
      nodes {
        code
        state
        grade
      }
    }
    all: allSheetDataAllSodData(
      filter: {
        measure: { eq: $measure }
        cycle: { eq: "Overall" }
        component: { eq: "Total" }
      }
    ) {
      nodes {
        code
        state
        grade
      }
    }
    allParticipation: allSheetDataParticipation(
      filter: { cycle: { eq: "Overall" }, component: { eq: "Total" } }
    ) {
      nodes {
        code
        state
        grade
      }
    }
    allCompetition: allSheetDataCompetition(
      filter: { cycle: { eq: "Overall" }, component: { eq: "Total" } }
    ) {
      nodes {
        code
        state
        grade
      }
    }
    allRepresentation: allSheetDataRepresentation(
      filter: { cycle: { eq: "Overall" }, component: { eq: "Total" } }
    ) {
      nodes {
        code
        state
        grade
      }
    }
    allPolarization: allSheetDataPolarization(
      filter: { cycle: { eq: "Overall" }, component: { eq: "Total" } }
    ) {
      nodes {
        code
        state
        grade
      }
    }

    homePageContent: allSheetDataHomepageContent {
      nodes {
        title
        value
      }
    }
  }
`;
